import { Container } from "react-bootstrap";
import Layout from "../components/layout/Layout";
import React from "react";
import { StaticImage } from "gatsby-plugin-image";

const InterviewManuelKlinge = () => {
  return (
    <Layout>
      <StaticImage
        src={"../assets/images/interviews/interview-zur-richtigen-zeit.jpg"}
        alt="Detharding Interview - Zur richtigen Zeit"
        placeholder="blurred"
        layout="fullWidth"
        quality={100}
      />
      <Container>
        <h1 className="gold-header d-flex justify-content-center pb-3">
          Zur richtigen Zeit am richtigen Ort
        </h1>
        <p>
          Wer in Kassel ein schönes Haus in attraktiver Lage sucht, muss schnell
          sein – denn gute Angebote sind oft ruck-zuck weg. Das dachte sich auch
          Manuel Klinge und beschloss, bei seinem ersten Maklertermin mit
          Christiane Detharding überpünktlich zu sein ...
        </p>
        <p>
          Doch als er zu seinem Auto kam, erlebte der ehemalige Eishockeyprofi
          und jetzige Sportdirektor der Kasseler Huskies eine unangenehme
          Überraschung: Ein Marder hatte im Motorraum sein Unwesen getrieben.
          Immerhin: Der Wagen sprang noch an und Manuel konnte sich auf den Weg
          machen – aber leider nur im Schneckentempo. Mit einiger Verspätung und
          „Schweißperlen auf der Stirn“ kam er schließlich zur vereinbarten
          Adresse. Doch Christiane Detharding blieb ganz entspannt: „Wir planen
          für jeden Besichtigungstermin sehr viel Zeit ein, damit sich unsere
          Kunden ganz in Ruhe ein Bild von der Immobilie machen können. Die
          Verspätung war also überhaupt kein Problem!“
        </p>
        <br />
      </Container>
      <StaticImage
        src={"../assets/images/interviews/interview-nummer-sicher.jpg"}
        alt="Detharding Interview - Auf Nummer sicher"
        placeholder="blurred"
        layout="fullWidth"
        quality={100}
      />
      <Container className="pb-5 mb-5">
        <h1 className="gold-header d-flex justify-content-center pb-3">
          Auf Nummer sicher ...
        </h1>
        <p>
          Als Christiane einige Tage später anrief und von einem weiteren
          interessanten Objekt am Kasseler Brasselsberg erzählte, beschlossen
          Manuel und seine Frau, schon am Vortag kurz vorbeizufahren. Gesagt,
          getan: Christiane gab ihnen die Adresse und sie machten sich auf den
          Weg. Nicht schlecht, dachten sich die beiden, aber das Nachbarhaus
          würde eigentlich noch viel besser zu uns passen.
        </p>
        <p>
          Das erzählten sie auch Christiane, die sich allerdings über ihre
          Beschreibung wunderte – und einen Verdacht hatte, dem sie noch am
          selben Abend nachging: Sie fuhr kurzerhand zur Immobilie, machte ein
          Selfie vor dem Haus und schickte es den Klinges aufs Handy: „Als meine
          Frau das Foto sah, war sie völlig aus dem Häuschen – denn wir hatten
          uns die falsche Hausnummer notiert und es war tatsächlich das
          Nachbargebäude, das zum Verkauf stand!“
        </p>
        <p>
          Pünktlich wie die Schlusssirene im Auestadion kamen Manuel und seine
          Frau dann am nächsten Tag zur Besichtigung, bei der sie auch von den
          „inneren Werten“ des Hauses begeistert waren. Schon kurze Zeit später
          beschlossen die beiden daher, dass hier am Brasselsberg ihre neue
          Heimat sein würde. „Als unsere Suchkunden konnten Herr und Frau Klinge
          das Haus als Erste und noch vor der Veröffentlichung des Exposés
          besichtigen“, erzählt Christiane. „Umso mehr hat es mich gefreut, dass
          die beiden sich hier sofort wohlgefühlt haben.“
        </p>
        <br />
        <h1 className="gold-header d-flex justify-content-center py-3">
          Happy End mit Blumenstrau
          <span style={{ textTransform: "none" }}>ß</span>
        </h1>
        <p>
          Eine tolle Überraschung gab es einige Wochen später auch beim
          Notartermin: „Frau Detharding hat meiner Frau einen Blumenstrauß
          überreicht, der einfach perfekt zu ihr passte“, schmunzelt Manuel
          Klinge. „Darüber hat sie sich in diesem Moment fast noch mehr gefreut
          als über den Kaufvertrag.“
        </p>
        <p>
          Anschließend luden die Klinges Christiane und Philip Detharding noch
          in ihr Lieblingsrestaurant ein. „Auf diese Weise wollten wir uns für
          die rundum gute Betreuung bedanken“, sagt Manuel Klinge. „Denn wir
          hatten von Anfang an das gute Gefühl, dass die beiden nicht nur extrem
          kompetent sind, sondern sich auch mit Herzblut dafür einsetzen, dass
          wir das passende Haus finden!“
        </p>
        <p>
          Bleibt eigentlich nur noch die Frage, welche Tiere zukünftig mit den
          Klinges das weitläufige Grundstück teilen werden. „Meine Frau war
          begeistert von den Alpakas in der Nachbarschaft“, lacht Manuel Klinge.
          „Derzeit sind bei uns Esel, Hühner und Ziegen im Gespräch – es bleibt
          spannend!“
        </p>
        <p>
          Wir wünschen Familie Klinge und ihren „Mitbewohnern“ alles Gute im
          neuen Zuhause!
        </p>
      </Container>
    </Layout>
  );
};

export default InterviewManuelKlinge;
